export enum ICustomerManagementRouteEnum {
  ALL = "customer-management.all",
  RECENT = "customer-management.recent",
  INACTIVE = "customer-management.inactive",
  BEST = "customer-management.best",
  CRITICAL = "customer-management.critical",
  DINE_IN_TRANSACTIONS = "customer-management.transactions.dine-in",
  DELIVERY_TRANSACTIONS = "customer-management.transactions.delivery",
  PICKUP_TRANSACTIONS = "customer-management.transactions.pickup",
  PAYMENT_LINK_TRANSACTIONS = "customer-management.transactions.payment-link",
}
