






















import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { AtomAutoSizeTextarea } from "@/v2/new-design-system/atom/auto-size-textarea";
import { MolFormControl } from "@/v2/new-design-system/mol/form-control";
const css = bemBuilder("org-form-text-area");
export default defineComponent({
  name: "OrgFormTextArea",
  components: {
    MolFormControl,
    AtomAutoSizeTextarea,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    errorMsg: {
      type: String,
      default: "",
    },
    successMsg: {
      type: String,
      default: "",
    },
    onInput: {
      type: Function as PropType<(value: string) => void>,
      required: true,
    },
    onTrack: {
      type: Function as PropType<(value: string) => void>,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 1,
    },
    hint: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      css,
    };
  },
});
