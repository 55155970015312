import Api from "@/api";
import { http } from "@/v2/repo/http.ts";
import {
  propOr,
  is,
  filter,
  when,
  pipe,
  take,
  trim,
  append,
  join,
  find,
} from "ramda";
import { identifyFeatureFlag } from "@/v2/core/feature-flag";
import { reportIdentify, reportGlobalTags } from "@chatfood/bug-reporter";

const hashCode = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
};

/**
 * Controll JWT Token within user's localStorage.
 *
 * @returns {{key: String, get: Function, set: Function, remove: Function, refreshServicesHeaders: Function }}
 */
export const jwtToken = {
  /** @type {String} localStorage key name */
  key: "oauth",

  /**
   * Get token from localStorage.
   *
   * @returns {String}
   */
  get() {
    let token;

    try {
      token = JSON.parse(window.localStorage.getItem(this.key));
      token = propOr(null, "access_token", token);
    } catch (e) {
      token = null;
    }

    return token;
  },

  /**
   * Storage token object into user's localStorage.
   *
   * @param {Object} value
   * @returns {void}
   * */
  set(value) {
    window.localStorage.setItem(this.key, JSON.stringify(value));
  },

  /** Cleanup token from user's localStorage */
  remove() {
    window.localStorage.removeItem(this.key);
    this.refreshServicesHeaders();
  },

  /**
   * Set authorization header into app services such as:
   * Broadcasting, Axios...
   *
   * @param {String} value Ex. `Bearer XXXX`
   * @returns {void}
   * */
  refreshServicesHeaders(token = null) {
    Api.defaults.headers.common["Authorization"] = token;
    http.defaults.headers.common["Authorization"] = token;
  },
};

/**
 * Controll access to active businesses from localStorage.
 *
 * @returns {{key: String, get: Function, set: Function}}
 */
export const activeBusiness = {
  key: "active_business",

  /**
   * Get the active business id from localStorage.
   *
   * @returns {String|null}
   */
  get() {
    try {
      return window.localStorage.getItem(this.key);
    } catch (e) {
      return null;
    }
  },

  /**
   * Store the active business id in the localStorage.
   *
   * @param {Object} value
   * @returns {String}
   * */
  set(value) {
    window.localStorage.setItem(this.key, value);
  },
};

/**
 * Remove object from list matching with the given ID.
 *
 * @param {Array} data
 * @param {String|Number} id
 *
 * @returns {Array}
 */
export const removeById = (data, id) => {
  if (!is(Array, data)) return data;

  return filter((i) => i.id !== id, data);
};

/**
 * Debounce function
 *
 * @param {Function} callback
 * @param {Number} wait Time in miliseconds to hold exec
 *
 * @returns {Function}
 */
export const debounce = (callback, wait) => {
  let timeout;

  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};

/**
 * Bootstraps the application loading all the mandatory resources
 * to run it.
 *
 * @param {String} token JWT to access the API
 * @param {Function} dispatch Dispatch method from the Vuex store. Eg. this.$store.dispatch
 * @param {Object} { ...services } All the services that needs to be contextualized
 *
 * @returns {void}
 */
export const bootstrap = async (
  token,
  dispatch,
  { gates, intercom, hotjar, analytics, urlParams }
) => {
  // Set given token to services headers
  jwtToken.refreshServicesHeaders(`Bearer ${token}`);

  // Load tenant
  const [tenant, businesses] = await Promise.all([
    dispatch("auth/fetch"),
    dispatch("businesses/fetch"),
  ]);

  try {
    reportIdentify({
      id: tenant.id,
      email: tenant.email,
    });
  } catch (e) {
    window.console.warn("Could not set user in the report system.\n", e);
  }

  const businessId = urlParams?.businessId || activeBusiness.get();
  const firstBusiness = businesses.data?.[0] ?? {};
  const selectedBusiness = businesses.data.find(
    (business) => business.id === businessId
  );
  const business = selectedBusiness ?? firstBusiness;

  const { organizationId } = business;

  try {
    reportGlobalTags({
      "business.id": business.id,
      "business.slug": business.url,
      "organization.id": tenant.organization.id,
    });
  } catch (e) {
    window.console.warn(
      "Could not set the global tags in the report system.\n",
      e
    );
  }

  try {
    const permissions = await dispatch(
      "auth/fetchUserPermissions",
      organizationId
    );
    // Contextualize Gates plugin
    gates.setUserPermissions(permissions);
  } catch (e) {
    window.console.warn("Could not fetch user permissions.", e);
  }

  // Identify user in the FeatureFlag system
  try {
    identifyFeatureFlag({
      id: tenant.id,
      firstName: tenant.firstName,
      lastName: tenant.lastName,
      email: tenant.email,
      roles: tenant.roles,
      businessId: business?.id,
      businessCountry: business?.country,
      businessUrl: business?.url,
      businessCreatedAt: new Date(business?.createdAt),
    });
  } catch (e) {
    window.console.warn(
      "Could not indentify user in the Feature Flag system.",
      e
    );
  }

  // Contextualize Intercom
  try {
    intercom.boot({
      tenant: tenant,
      organization: tenant.organization,
    });
  } catch (e) {
    window.console.warn("Could not boot Intercom.\n", e);
  }

  // Contextualize Hotjar
  try {
    hotjar.identify(tenant);
  } catch (e) {
    window.console.warn("Could not identify user on Hotjar.\n", e);
  }

  // Contextualize Analytics plugin
  try {
    analytics.identify(tenant.id, tenant);
  } catch (e) {
    window.console.warn("Could not identify user on analytics.\n", e);
  }

  // Load base resources
  const requests = [dispatch("outlets/fetch")];

  if (gates.can("VIEW_LIVE_ORDERS")) {
    requests.push(dispatch("liveOrders/fetch"));
  }

  const [outlets] = await Promise.all(requests);

  intercom.update({
    businesses: businesses.data,
    outlets: outlets.data,
  });
};

/**
 * Clone the given ES6 class instance with all of its data.
 *
 * @param {Object} origin Any ES6 class instance
 * @returns {Object} Same given ES6 class instance
 */
export const cloneInstance = (origin) => {
  return Object.assign(Object.create(Object.getPrototypeOf(origin)), origin);
};

/**
 * Generate a random HEX code based on the amount of colors needed.
 *
 * @param {Number} amount
 * @returns {String|Array}
 */
export const randomColorGenerator = (amount = 1) => {
  const colors = [];

  while (colors.length != amount) {
    const check = "#" + (Math.random().toString(16) + "0000000").slice(2, 8);
    if (!colors.includes(check)) {
      colors.push(check);
    }
  }

  return colors;
};

/**
 * Generate a HEX code based on text sent in an array.
 *
 * @param {Array} text
 * @returns {String|Array}
 */
export const colorBasedOnText = (size = []) => {
  const colors = [];
  while (colors.length != size.length) {
    colors.push("#".concat(intToRGB(hashCode(size[colors.length]))));
  }
  return colors;
};

/**
 * Format a given number in order to be easily readble by human.
 *
 * @param {Number} number
 * @param {Number} precision
 * @returns {String}
 */
export const numberShortner = (number, precision) => {
  if (!is(Number, number)) {
    throw TypeError("Only number is accepted.");
  }

  const symbol = find(
    (obj) => number < obj.value * 1e3,
    [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ]
  );

  return numberHumanizer(number / symbol.value, precision) + symbol.symbol;
};

/**
 * Format a given number in order to be easily readble by human.
 *
 * @param {Number} number
 * @param {Number} precision
 * @returns {String}
 */
export const numberHumanizer = (number, precision) => {
  if (!is(Number, number)) {
    throw TypeError("Only number is accepted.");
  }

  const formattedNumber = number.toFixed(precision);
  return new Intl.NumberFormat().format(formattedNumber);
};

/**
 * Truncate a string.
 *
 * @param {String} string
 * @param {Number} limit
 * @param {String} suffix
 */
export const truncate = (string, limit, suffix = "...") => {
  if (!is(String, string)) {
    throw TypeError("Only string is accepted.");
  }

  return when(
    (str) => str.length > limit,
    pipe(take(limit), trim, append(suffix), join(""))
  )(string);
};
