<template>
  <div class="google-maps">
    <div ref="map" class="google-maps__map"></div>
    <Icon icon="map-marker" :size="41" class="google-maps__marker" />
    <input
      ref="search"
      :placeholder="searchPlaceholder"
      class="google-maps__search"
      type="text"
    />
  </div>
</template>

<script>
import Icon from "../Icon/Index.vue";
import { useGoogleMaps } from "./useGoogleMaps";
import { names as countriesList } from "./countries";
import { has, isNil } from "ramda";
import { debounce } from "../utils";

const { state, loadLibrary } = useGoogleMaps();

/**
 * @deprecated
 * use `AtomMap` from `@/v2/new-design-system` instead
 */
export default {
  name: "GoogleMaps",
  components: {
    Icon,
  },
  props: {
    country: {
      type: String,
      required: true,
    },
    coordinates: {
      type: Object,
      required: true,
      validator: (val) => has("lat", val) && has("lng", val),
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    map: null,
    gMaps: state,
    geocoder: null,
    search: null,
    mapSettings: {
      disableDefaultUI: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    },
  }),
  watch: {
    "gMaps.isReady": {
      handler(val) {
        if (val) {
          this.initMap();
        }
      },
    },
  },
  created() {
    loadLibrary();
  },
  mounted() {
    if (this.gMaps.isReady) {
      this.initMap();
    }
  },
  methods: {
    initMap() {
      this.geocoder = new window.google.maps.Geocoder();

      // load the map
      this.map = new window.google.maps.Map(this.$refs.map, this.mapSettings);

      // add event listener to update coordinates on center changed
      window.google.maps.event.addListener(
        this.map,
        "center_changed",
        this.updateCoordinates
      );
      this.bindMapLocation();

      this.initSearch();
    },
    bindMapLocation() {
      if (!isNil(this.coordinates.lat) && !isNil(this.coordinates.lng)) {
        this.bindMapFromCoordinates(this.coordinates);
        return;
      }

      const countryName = countriesList[this.country];
      this.bindMapFromString(countryName);
    },
    bindMapFromString(address) {
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
          this.map.fitBounds(results[0].geometry.viewport);
        }
      });
    },
    bindMapFromCoordinates(coordinates) {
      this.geocoder.geocode(
        {
          latLng: new window.google.maps.LatLng(
            coordinates.lat,
            coordinates.lng
          ),
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            this.map.fitBounds(results[0].geometry.viewport);
          }
        }
      );
    },
    initSearch() {
      // create the searchbox
      this.search = new window.google.maps.places.Autocomplete(
        this.$refs.search
      );

      // set country restrictions
      this.search.setComponentRestrictions({
        country: [this.country],
      });

      // add event listener when a place is selected
      this.search.addListener("place_changed", () => {
        const place = this.search.getPlace();

        if (!place.geometry) return;

        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        this.map.setCenter({ lat, lng });
        this.map.fitBounds(place.geometry.viewport);
      });
    },
    updateCoordinates: debounce(function () {
      const lat = this.map.getCenter().lat();
      const lng = this.map.getCenter().lng();

      this.$emit("changed", { lat, lng });
    }, 2000),
  },
};
</script>

<style lang="scss" scoped>
.google-maps {
  width: 100%;
  min-width: 100px;
  height: 100%;
  min-height: 100px;
  position: relative;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;

  &__search {
    position: absolute;
    top: 10px;
    left: 10px;
    max-width: 450px;
    display: block;
    width: 100%;
    color: #444444;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 11px 16px;
    font-size: 14px;
    line-height: 18px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }

  &__marker {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20.5px;
    margin-top: -41px;
    pointer-events: none;
  }

  &__map {
    position: absolute;
    left: 0;
    top: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }
}

/deep/ .gm-style-cc,
/deep/ a {
  display: none !important;
}
</style>
