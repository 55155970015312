import { useHelpers } from "@/v2/composable/use-helpers";
import Vue from "vue";
import { NavigationGuardNext, Route } from "vue-router";
import { getActiveOutlet, setActiveOutlet } from "./utils";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const optionalOutletFillerMiddleware = (
  to: Route,
  _: Route,
  next: NavigationGuardNext<Vue>
) => {
  if (to.params.outletId) {
    setActiveOutlet(to.params.outletId);
    next();
    return;
  }

  let currentOutletId = getActiveOutlet();

  const outletIds = useHelpers()
    .getOutletsFromBusiness(to.params.businessId)
    .map((outlet) => outlet.id);

  if (!currentOutletId || !outletIds.includes(currentOutletId)) {
    currentOutletId = outletIds[0];
    setActiveOutlet(currentOutletId);
  }

  next({
    name: to.name ?? undefined,
    params: {
      ...to.params,
      outletId: currentOutletId,
    },
    query: to.query,
  });
};

export { optionalOutletFillerMiddleware };
