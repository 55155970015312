import { is, mergeDeepRight, propOr, join, pluck, compose } from "ramda";
import { isNilOrEmpty } from "ramda-extension";
import dayjs from "dayjs";

/**
 * Intercom widget class management.
 *
 * To find more info about Intercom Widget:
 * https://www.intercom.com/help/en/
 * https://developers.intercom.com/
 */
class Intercom {
  /**
   * Intercom Class Constructor.
   *
   * @param {{ appId: String, debug: Boolean }} config
   */
  constructor(config) {
    this.appId = propOr(null, "appId", config);
    this.debug = propOr(false, "debug", config);
    this.hiddenUpTo = propOr(null, "hiddenUpTo", config);
    this.enabled = !isNilOrEmpty(this.appId);
    this.userId = null;

    if (!this.enabled) {
      this.log("disabled");
      return;
    }

    this.log("mounted");
    this.loadScript();
  }

  /**
   * Verify if Intercom widget script has been already loaded.
   *
   * @returns {Boolean}
   */
  hasScript() {
    const hasScript = Array.from(document.getElementsByTagName("script")).some(
      (script) => script.src.includes("widget.intercom.io")
    );

    this.log(hasScript ? "check script [loaded]" : "check script [not loaded]");

    return hasScript;
  }

  /**
   * If Intercom script has not been loaded yet
   * it will run the default snipet to load it.
   *
   * @returns {Promise}
   */
  loadScript() {
    if (this.hasScript()) return Promise.resolve();

    return new Promise((resolve, reject) => {
      const i = () => {
        i.c(arguments);
      };
      i.q = [];
      i.c = (args) => {
        i.q.push(args);
      };
      window.Intercom = i;

      const scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.async = true;
      scriptTag.src = `https://widget.intercom.io/widget/${this.appId}`;
      document.head.appendChild(scriptTag);

      scriptTag.onload = () => {
        this.log("script loaded");
        return resolve;
      };
      scriptTag.onerror = () => {
        this.log("script loading failed");
        return reject;
      };
    });
  }

  /**
   * Call window.Intercom method.
   *
   * @param {String} event
   * @param {Object} data
   */
  trigger(event, data = {}) {
    if (!this.enabled) {
      this.log("triggered while disabled");
      return;
    }

    if (!window || !window.Intercom) {
      this.log("triggered but window.Intercom unavailable");
      return;
    }

    window.Intercom(event, data);
    this.log(`triggered ${event}`, data);
  }

  /**
   * Logs the action in the browser console.
   *
   * @param {String} label
   * @param {Any} extra
   */
  log(label, extra = "") {
    if (this.debug && window) {
      window.console.log(`Intercom: ${label}`, extra);
    }
  }

  /**
   * Boot the widget with the given data.
   * Doc: https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomboot-intercomsettings
   *
   * @param {Object} data Data to be sent along with boot event
   * @param {Object} data.tenant Tenant information
   * @param {Object} data.organization Organization information
   * @param {Array} data.businesses List of businesses - must contain URL attr
   * @param {Array} data.outlets List of outlets - must contain URL attr
   * @param {Object} data.extra Any extra information to be attached to the main data
   *
   * @returns {void}
   */
  boot(data) {
    this.trigger("boot", this.normalizeData(data));
  }

  /**
   * Update the widget settings accordingly with the given data.
   * Doc: https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomupdate
   *
   * @param {Object} data Data to be sent along with update event
   * @param {Object} data.tenant Tenant information
   * @param {Object} data.organization Organization information
   * @param {Array} data.businesses List of businesses - must contain URL attr
   * @param {Array} data.outlets List of outlets - must contain URL attr
   * @param {Object} data.extra Any extra information to be attached to the main data
   *
   * @returns {void}
   */
  update(data) {
    this.trigger("update", this.normalizeData(data));
  }

  /**
   * Shutdown the widget and reset to default state.
   * Doc: https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomshutdown
   *
   * @returns {void}
   */
  shutdown() {
    this.trigger("shutdown");
  }

  /**
   * Open the widget if bootted.
   * Doc: https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomshow
   *
   * @returns {void}
   */
  show() {
    this.trigger("show");
  }

  /**
   * Hide the widget if bootted.
   * Doc: https://developers.intercom.com/installing-intercom/docs/intercom-javascript#section-intercomhide
   *
   * @returns {void}
   */
  hide() {
    this.trigger("hide");
  }

  /**
   * Sanitize the tenant information.
   *
   * @param {Object} tenant
   * @returns {Object}
   */
  sanitizeTenant(tenant) {
    this.userId = tenant.id;

    return {
      user_id: tenant.id,
      name: `${tenant.firstName} ${tenant.lastName}`,
      "First Name": tenant.firstName,
      "Last Name": tenant.lastName,
      email: tenant.email,
      phone: tenant.phone,
      Group: "Tenant",
      Roles: tenant.roles.join(", "),
    };
  }

  /**
   * Sanitize the organization information.
   *
   * @param {Object} organization
   * @returns {Object}
   */
  sanitizeOrganization(organization) {
    return {
      company: {
        id: organization.id,
        name: organization.name,
        Country: organization.country,
        created_at: organization.createdAt
          ? dayjs(organization.createdAt).unix()
          : null,
      },
    };
  }

  /**
   * Get all the businesses urls and build an string comma separated.
   *
   * @param {Array} businesses
   * @returns {Object}
   */
  sanitizeBusinesses(businesses) {
    return {
      Businesses: compose(join(", "), pluck("url"))(businesses),
    };
  }

  /**
   * Get all the businesses urls and build an string comma separated.
   *
   * @param {Array} outlets
   * @returns {Object}
   */
  sanitizeOutlets(outlets) {
    return {
      Outlets: compose(join(", "), pluck("url"))(outlets),
    };
  }

  /**
   * Normalize the data to build the widget settings
   *
   * @param {Object|null} data.tenant Tenant information
   * @param {Object|null} data.organization Organization information
   * @param {Array|null} data.businesses List of businesses - must contain URL attr
   * @param {Array|null} data.outlets List of outlets - must contain URL attr
   * @param {Object|null} data.extra Any extra information to be attached to the main data
   *
   * @returns {Object}
   */
  normalizeData({
    tenant = null,
    organization = null,
    businesses = null,
    outlets = null,
    extra = null,
  }) {
    let data = {
      app_id: this.appId,
      user_id: this.userId,
    };

    if (is(Object, tenant)) {
      data = mergeDeepRight(data, this.sanitizeTenant(tenant));
    }

    if (is(Object, organization)) {
      data = mergeDeepRight(data, this.sanitizeOrganization(organization));
    }

    if (is(Object, businesses)) {
      data = mergeDeepRight(data, this.sanitizeBusinesses(businesses));
    }

    if (is(Object, outlets)) {
      data = mergeDeepRight(data, this.sanitizeOutlets(outlets));
    }

    if (is(Object, extra)) {
      data = mergeDeepRight(data, extra);
    }

    if (this.hiddenUpTo && window.innerWidth <= this.hiddenUpTo) {
      data.hide_default_launcher = true;
    }

    return data;
  }
}

export default Intercom;
