import VueRouter from "vue-router";
import { ISidebarProps } from "../props";
import { t } from "@/i18n";
import { canViewGetStarted } from "./can-view-get-started";
import { canViewDashboard } from "./can-view-dashboard";
import { generateURL } from "@/v2/module/sidebar/method/generate-url";
import { IFlags } from "@/v2/core/feature-flag/domain";
import {
  sidebarTypeformLinkModal,
  sidebarTypeformShowModal,
} from "@/v2/module/sidebar/state";
import { InventoryRouteEnum } from "@/module/inventory";
import { CampaignRouteEnum } from "@/module/campaign";
import { VIEW_CAMPAIGNS } from "@/utils/enums/permissions";

type MenuParams = {
  permissions: Array<string>;
  businessId: string;
  businessName: string;
  router: VueRouter;
  flagsAvailable: Array<IFlags>;
};

export function setMainMenu(
  params: MenuParams
): ISidebarProps["config"]["main"] {
  const { permissions, businessId, businessName, router, flagsAvailable } =
    params;

  const routes = [
    {
      key: "get-started",
      label: t("menu.get_started"),
      icon: "rocket",
      visible: canViewGetStarted(permissions.includes("VIEW_ONBOARDING")),
      action: {
        routerLink: "onboarding",
        func: (): void => {},
      },
    },
    {
      key: "dashboard",
      label: t("menu.dashboard"),
      icon: "dashboard-menu",
      visible: canViewDashboard(
        permissions.includes("VIEW_DASHBOARD"),
        permissions.includes("VIEW_ONBOARDING")
      ),
      action: {
        routerLink: flagsAvailable.includes("experimental-metabase-reports")
          ? "reports.analytics"
          : "dashboard.index",
      },
    },
    {
      key: "live-orders",
      label: t("menu.live-orders"),
      icon: "live-orders-menu",
      visible: permissions.includes("VIEW_LIVE_ORDERS"),
      action: {
        routerLink: "orders.live",
      },
    },
    {
      key: "tabs",
      label: t("menu.tabs"),
      icon: "min-basket",
      visible: permissions.includes("VIEW_LIVE_TABS"),
      action: {
        routerLink: "tabs.live",
      },
    },
    {
      key: "operations",
      label: t("menu.operations"),
      icon: "order-history-menu",
      visible: permissions.some((permission) =>
        [
          "VIEW_INVENTORY",
          "VIEW_SETTINGS",
          "VIEW_ORDERS",
          "VIEW_TABLE_PAYMENTS",
          "VIEW_TIPS",
          "VIEW_LIVE_TABS",
        ].includes(permission)
      ),
      submenu: [
        {
          label: "",
          items: [
            {
              key: "inventory",
              label: t("menu.inventory"),
              visible: permissions.includes("VIEW_INVENTORY"),
              action: {
                routerLink: InventoryRouteEnum.MAIN,
                func: (): void => {},
              },
            },
            {
              key: "pause-orders",
              label: t("menu.pause-orders"),
              visible: permissions.includes("VIEW_SETTINGS"),
              action: {
                routerLink: "settings.index",
              },
            },
            {
              key: "order-history",
              label: t("menu.order-history"),
              visible: permissions.includes("VIEW_ORDERS"),
              action: {
                routerLink: "orders.index",
              },
            },
            {
              key: "table-payments",
              label: t("menu.table_payments"),
              visible: permissions.includes("VIEW_TABLE_PAYMENTS"),
              action: {
                routerLink: "table-payment.index",
                useTracking: true,
              },
            },
            {
              key: "tips",
              label: t("menu.tips"),
              visible: permissions.includes("VIEW_TIPS"),
              action: {
                routerLink: "tips.index",
                useTracking: true,
              },
            },
            {
              key: "tabs",
              label: t("menu.tabs"),
              visible: permissions.includes("VIEW_LIVE_TABS"),
              action: {
                routerLink: "tabs.live",
                useTracking: true,
              },
            },
            {
              key: "tab-history",
              label: t("menu.tab_history"),
              visible: permissions.includes("VIEW_TABS_HISTORY"),
              action: {
                routerLink: "tabs.history",
                useTracking: true,
              },
            },
          ],
        },
      ],
    },
    {
      key: "booking",
      label: t("menu.booking"),
      icon: "book",
      visible: permissions.some((permission) =>
        ["VIEW_RESERVATIONS", "VIEW_SPOTS_OCCUPANCY"].includes(permission)
      ),
      submenu: [
        {
          label: "",
          items: [
            {
              key: "reservations",
              label: t("menu.reservations"),
              visible: permissions.includes("VIEW_RESERVATIONS"),
              action: {
                routerLink: "reservations.index",
                useTracking: true,
              },
            },
            {
              key: "floor-plan",
              label: t("menu.floor_plan"),
              visible: permissions.includes("VIEW_SPOTS_OCCUPANCY"),
              action: {
                routerLink: "spots-occupancy.index",
                useTracking: true,
              },
            },
          ],
        },
      ],
    },
    {
      key: "marketing",
      label: t("menu.marketing"),
      checkPosition: true,
      icon: "marketing-menu",
      visible: permissions.some((permission) =>
        [
          "EDIT_WIDGET",
          "VIEW_DISCOUNTS",
          "VIEW_CHANNELS",
          "EDIT_REACTIVATION",
          "VIEW_MARKETING",
          "VIEW_CUSTOMERS",
          VIEW_CAMPAIGNS,
        ].includes(permission)
      ),
      submenu: [
        {
          label: "",
          items: [
            {
              label: t("menu.campaigns"),
              visible: permissions.includes(VIEW_CAMPAIGNS),
              action: {
                routerLink: CampaignRouteEnum.MAIN,
                useTracking: true,
              },
            },
            {
              label: t("menu.customers"),
              visible:
                flagsAvailable.includes("customers-management") &&
                permissions.includes("VIEW_CUSTOMERS"),
              action: {
                routerLink: "customer-management.index",
                useTracking: true,
              },
            },
            {
              label: t("menu.discounts"),
              visible: permissions.includes("VIEW_DISCOUNTS"),
              action: {
                routerLink: "discounts.index",
              },
            },
            {
              label: t("menu.loyalty"),
              visible: permissions.includes("VIEW_LOYALTY"),
              action: {
                routerLink: "loyalty.index",
              },
            },
            {
              label: t("menu.sales_channels"),
              visible: permissions.includes("VIEW_CHANNELS"),
              action: {
                routerLink: "sales_channels.index",
                func: (): void => {},
              },
            },
            {
              label: t("menu.reactivation_reminder"),
              visible: permissions.includes("EDIT_REACTIVATION"),
              action: {
                routerLink: "marketing.reactivation.email",
              },
            },
            {
              label: t("menu.sms_marketing"),
              visible: true,
              action: {
                func: (): void => {
                  sidebarTypeformShowModal.value = true;
                  sidebarTypeformLinkModal.value = `https://chatfood.typeform.com/to/WdGplE?business_name=${businessName}&business_id=${businessId}`;
                },
              },
            },
            {
              label: t("menu.whatsapp_marketing"),
              visible: true,
              action: {
                func: (): void => {
                  sidebarTypeformShowModal.value = true;
                  sidebarTypeformLinkModal.value = `https://chatfood.typeform.com/to/GYrP0ATo?utm_source=dashboard&utm_content=whatsapp+request`;
                },
              },
            },
            {
              label: t("menu.qr_code_digital_menu"),
              visible: permissions.includes("VIEW_MARKETING"),
              action: {
                routerLink: "qr-code.digital-menu",
              },
            },
          ],
        },
      ],
    },
    {
      key: "menu",
      label: t("menu.menu"),
      icon: "menu-menu",
      visible: permissions.some((permission) =>
        ["VIEW_ITEMS", "VIEW_MODIFIER_LISTS", "VIEW_MENUS"].includes(permission)
      ),
      submenu: [
        {
          label: "",
          items: [
            {
              label: t("menu.items-modifiers"),
              visible: permissions.includes("VIEW_ITEMS"),
              action: {
                routerLink: "items.index",
                func: (): void => {},
              },
            },
            {
              label: t("menu.modifier-lists"),
              visible: permissions.includes("VIEW_MODIFIER_LISTS"),
              action: {
                routerLink: "modifier-lists.index",
              },
            },
            {
              label: t("menu.menus"),
              visible: permissions.includes("VIEW_MENUS"),
              action: {
                routerLink: "menus.index",
              },
            },
          ],
        },
      ],
    },
    {
      key: "payment-links",
      label: t("menu.payment_links"),
      icon: "online-payments",
      visible: permissions.includes("VIEW_PAYMENT_LINKS"),
      action: {
        routerLink: "payment-links.index",
        useTracking: true,
      },
    },
    {
      key: "inbox",
      label: t("menu.inbox"),
      icon: "whatsapp",
      visible: permissions.includes("VIEW_INBOX"),
      action: {
        routerLink: "inbox",
        useTracking: true,
      },
    },
    {
      key: "report",
      label: t("menu.reports"),
      icon: "reports",
      visible:
        !flagsAvailable.includes("experimental-metabase-reports") &&
        permissions.includes("VIEW_REPORTS"),
      action: {
        routerLink: "reports.index",
        useTracking: true,
      },
    },
    {
      key: "report",
      label: t("menu.reports"),
      icon: "reports",
      visible:
        flagsAvailable.includes("experimental-metabase-reports") &&
        permissions.includes("VIEW_REPORTS"),
      submenu: [
        {
          label: "",
          items: [
            {
              label: t("menu.reports_overview"),
              visible: true,
              action: {
                routerLink: "reports.index",
                useTracking: true,
              },
            },
            {
              label: t("menu.reports_sales_summary"),
              visible: true,
              action: {
                routerLink: "reports.sales-summary",
                useTracking: true,
              },
            },
          ],
        },
      ],
    },
    {
      key: "payouts",
      label: t("menu.payouts"),
      icon: "billing",
      visible: permissions.includes("VIEW_PAYOUTS"),
      action: {
        routerLink: "payouts.index",
        useTracking: true,
      },
    },
    {
      key: "reviews",
      label: t("menu.reviews"),
      icon: "star",
      visible: permissions.includes("VIEW_REVIEWS"),
      action: {
        routerLink: "reviews.index",
        useTracking: true,
      },
    },
  ];

  return generateURL(routes, router, { businessId });
}
