import { httpInbox } from "../http-inbox";
import { IFetchInboxSetupApiResponse } from "./api-response.factory";

export async function fetchInboxSetup(
  businessId: string
): Promise<IFetchInboxSetupResponse> {
  const response = await httpInbox.get<IFetchInboxSetupApiResponse>(
    `/businesses/${businessId}/setup`
  );

  return {
    isWhatsAppEnabled: Boolean(response.data.whatsapp),
    isInstagramEnabled: Boolean(response.data.instagram),
  };
}

export interface IFetchInboxSetupResponse {
  isWhatsAppEnabled: boolean;
  isInstagramEnabled: boolean;
}
